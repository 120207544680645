<template>
  <div id="app">
    <AppHeader />
    <NoteForm @add-note="addNote" />
    <NoteList :notes="notes" @delete-note="deleteNote" @edit-note="editNote" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import NoteForm from "./components/NoteForm.vue";
import NoteList from "./components/NoteList.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AppHeader,
    NoteForm,
    NoteList,
  },
  computed: {
    ...mapGetters(["notes"]),
  },
  methods: {
    ...mapActions(["addNote", "deleteNote", "editNote"]),
  },
};
</script>

<style>
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
