<template>
  <header>
    <h1>Google Keep Clone</h1>
  </header>
</template>

<style scoped>
header {
  background-color: #f5ba13;
  padding: 20px;
  text-align: center;
  color: white;
}
</style>
