import { createStore } from "vuex";

export default createStore({
  state: {
    notes: [],
  },
  mutations: {
    addNote(state, note) {
      state.notes.push({
        ...note,
        id: Date.now(),
      });
    },
    deleteNote(state, noteId) {
      state.notes = state.notes.filter((note) => note.id !== noteId);
    },
    editNote(state, editedNote) {
      const index = state.notes.findIndex((note) => note.id === editedNote.id);
      if (index !== -1) {
        state.notes[index] = editedNote;
      }
    },
  },
  actions: {
    addNote({ commit }, note) {
      commit("addNote", note);
    },
    deleteNote({ commit }, noteId) {
      commit("deleteNote", noteId);
    },
    editNote({ commit }, note) {
      commit("editNote", note);
    },
  },
  getters: {
    notes: (state) => state.notes,
  },
});
