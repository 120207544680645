<template>
  <div class="note-form">
    <input v-model="title" placeholder="Title" />
    <textarea v-model="content" placeholder="Take a note..."></textarea>
    <button @click="addNote">Add</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      content: "",
    };
  },
  methods: {
    addNote() {
      if (this.title && this.content) {
        this.$emit("add-note", { title: this.title, content: this.content });
        this.title = "";
        this.content = "";
      }
    },
  },
};
</script>

<style scoped>
.note-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
input,
textarea {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
button {
  background-color: #f5ba13;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}
</style>
