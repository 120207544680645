<template>
  <div class="note">
    <div v-if="!isEditing">
      <h2>{{ note.title }}</h2>
      <p>{{ note.content }}</p>
      <button @click="startEditing">Edit</button>
      <button @click="deleteNote">Delete</button>
    </div>
    <div v-else>
      <input v-model="editTitle" placeholder="Title" />
      <textarea v-model="editContent" placeholder="Content"></textarea>
      <button @click="saveEdit">Save</button>
      <button @click="cancelEdit">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["note"],
  data() {
    return {
      isEditing: false,
      editTitle: this.note.title,
      editContent: this.note.content,
    };
  },
  methods: {
    startEditing() {
      this.isEditing = true;
    },
    cancelEdit() {
      this.isEditing = false;
      this.editTitle = this.note.title;
      this.editContent = this.note.content;
    },
    saveEdit() {
      this.$emit("edit-note", {
        id: this.note.id,
        title: this.editTitle,
        content: this.editContent,
      });
      this.isEditing = false;
    },
    deleteNote() {
      this.$emit("delete-note", this.note.id);
    },
  },
};
</script>

<style scoped>
.note {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px #ccc;
}
button {
  background-color: #f5ba13;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}
input,
textarea {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
}
</style>
