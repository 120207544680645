<template>
  <div class="note-list">
    <NoteItem
      v-for="note in notes"
      :key="note.id"
      :note="note"
      @delete-note="deleteNote"
      @edit-note="editNote"
    />
  </div>
</template>

<script>
import NoteItem from "./NoteItem.vue";

export default {
  components: { NoteItem },
  props: ["notes"],
  methods: {
    deleteNote(noteId) {
      this.$emit("delete-note", noteId);
    },
    editNote(note) {
      this.$emit("edit-note", note);
    },
  },
};
</script>

<style scoped>
.note-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
